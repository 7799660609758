html{
  font-size: 12px;
}
img {
  max-width:100%;
}
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
.App {
  text-align: center;
  width:100%;
}

.App-header {
  background-image: url('https://www.mysitetest.net/images/space-background-top.png');
  background-repeat:repeat-x;
  background-position: 0% 0%;
  height: 206px;
  color: white;
  width:100%;
  -moz-box-shadow:  0px 8px 8px -4px rgba(0,0,0,0.62);
  -webkit-box-shadow: 0px 8px 8px -4px rgba(0,0,0,0.62);
  box-shadow: 0px 8px 8px -4px rgba(0,0,0,0.62);
  z-index:1000;
}

.App-title {
  font-size: 1.5em;
}
footer{
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height:80px;
  background-image: url('https://www.mysitetest.net/images/space-background-btm.png');
  background-repeat: repeat-x;
  background-position: bottom left;
}
.footer-text {
  padding: 20px 0 0 40px;
  font-size:1.2em;
  color:#ffffff;
}
body {
   font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
   background-color:#4d2971;
}
.logo-section{
  width:570px;
  text-align:left;
  margin-left:40px;
}
.book-rocket{
  float:left;
  margin-top:10px;
}
.logo {
  float:left;
  margin:20px 0 0px 20px;
  display:block;
}
.tagline{
  float: left;
  font-size:16px;
  text-align:left;
  color:white;
  text-decoration:none !important;
  margin:0 0 20px 20px;
}
.amazon {
  font-size:.8em;
  font-style:italic;
}
.small-text {
  clear:both;
  font-size:.9em;
  text-align:center;
}
.movie-list-container{
  width:70%;
  text-align:center;
  margin:auto;
  padding-left:30px;
}
.movie-list {
  margin: 40px 0px 80px 30px;
  display: flex;
  flex-direction: row;
  flex-wrap:wrap;
}
.each-book {
  margin-right:80px;
  margin-bottom:100px;
  font-size: 1.3em;
}
.each-book a{
    text-decoration:none;
    color:#ffffff;
}
.thebook {
  width: 188px;
  /*height:200px;*/
}
.booklist-title{
  clear:both;
  width: 180px;
  padding: 20px 0 10px 0;
}
.pages {
  width:90%;
  margin:auto;
  margin-bottom: 40px;
  margin-top:-40px;
  text-align:center;
}
.pages-top {
  width:90%;
  margin:auto;
  margin-bottom: 40px;
  margin-top: 5px;
  text-align:center;
  display:none;
}
.button-page {
   font-family: Arial, Helvetica, sans-serif;
   color:#ffffff;
   font-size:1.5em;
   float:left;
   margin-left:16px;
   width: 30px;
   height: 30px;
   border: 1px solid #fff;
   background:#4d21b6;
   text-align:center;
   padding-top:2px;
  -moz-box-shadow:  4px 5px 4px -1px rgba(0,0,0,0.62);
  -webkit-box-shadow: 4px 5px 4px -1px rgba(0,0,0,0.62);
   box-shadow: 4px 5px 4px -1px rgba(0,0,0,0.62);
   cursor:pointer;
}
.button-page:hover {
  background:#af77f6;
}
.center-pages{
  width: 300px;
  text-align:center;
  margin:auto;
}
.book-image, .book-image-p2 img{
  -moz-box-shadow:  8px 10px 8px -4px rgba(0,0,0,0.62);
  -webkit-box-shadow: 8px 10px 8px -4px rgba(0,0,0,0.62);
  box-shadow: 8px 10px 8px -4px rgba(0,0,0,0.62);
}
.book-image-p2 {
  float:left;
  max-height:300px;
}
.author-section {
  float:left;
  margin-left:60px;
 }
 .book-title{
  float:left;
  font-size: 2em;
  font-weight:bold;
  margin: 0 0 30px 0;
  text-shadow: 2px 2px 4px #000000;
 }
 .author-image{
   max-width:130px;
   -webkit-border-radius: 16px; 
   -moz-border-radius: 16px; 
   border-radius: 16px;
   -moz-box-shadow:    1px 1px 4px 5px rgba(9, 9, 9, 0.3);
   -webkit-box-shadow: 1px 1px 4px 5px rgba(9, 9, 9, 0.3);
   box-shadow:         1px 1px 4px 5px rgba(9, 9, 9, 0.3);
 }
 .book-description {
   text-align:left;
   font-size: 1.4em;
   line-height: 1.9em;
   padding-top:30px;
   max-width:1000px;
 }
 .button-back {
  color:#ffffff;
  font-size:1.5em;
  float:left;
  width: 110px;
  height: 45px;
  border: 1px solid #fff;
  background:#4d21b6;
  text-align:center;
  padding-top:5px;
  margin-left:15px;
  -webkit-box-shadow: 8px 10px 8px -4px rgba(0,0,0,0.62);
  -moz-box-shadow: 8px 10px 8px -4px rgba(0,0,0,0.62);
  box-shadow: 8px 10px 8px -4px rgba(0,0,0,0.62);
  text-align:center;
  margin: 40px 0 60px 0;
}
.button-back:hover{
  background-color: #af77f6;
}
 .background-main{
  background-color:#4d2971;
  background-image: url('https://www.mysitetest.net/images/space-background2.png');
  background-repeat: no-repeat;
  background-position: right top;
  background-image: url('https://www.mysitetest.net/images/space-background-shadow.png');
  background-position: left top;
  background-repeat: repeat-x;
  margin: -10px;
 }
 .content-area{
   width:80%;
   text-align:center;
   margin:auto;
   padding-left:30px;
   padding-top:40px;
   padding-bottom:40px;
   color:#ffffff;
 }
 .clearfix {
   clear:both;
 }
 .spacer {
   clear: both;
   height:60px;
   line-height:60px;
 }
 @media only screen and (max-width: 1200px) {
  .movie-list {
    margin-left: 100px;
   }
   .each-book {
    margin-right: 150px;
  }
 }
 @media all and (max-width: 1100px) {
  .movie-list-container {
    width: 82%;
     }
  .each-book {
      width: 180px;
      margin-right: 160px;
  }
  .each-book {
    width: 180px;
    margin-right: 60px;
    margin-left: 20px;
    font-size: 1.3em;
  }
 } 
 @media all and (max-width:768px) {
  .App-header {
    width:100%;
  }
  .movie-list-container {
    width: 86%;
  }
  .movie-list {
    margin-left: 30px;
   }
  .each-book {
    width: 180px;
    margin-right: 100px;
    margin-left: 20px;
    font-size: 1.3em;
   }
   footer{
    height:40px;
   }
  .footer-text {
    padding: 8px 0 0 40px;
    font-size: 1.2em;
   }
   .book-title {
    padding-top:20px !important;
  }
 }
 @media all and (max-width:750px) {
   .each-book{
    margin-right: 60px;
   }
   .logo-section {
    margin-left:0px;
  }
  .book-rocket{
    margin-top: 25px;
    width: 150px;
  }
  .logo {
    width: 260px;
    margin: 25px 0 0 7px;
  }
  .tagline {
    margin: 20px 0 0 7px;
    font-size:12px;
  }
  .pages-top {
    display: block;
    width: 100%;
  }
 }
 @media all and (max-width:650px) {
  .each-book{
   margin-right: 60px;
   margin-left: 0px;
  }
  .logo-section {
   margin-left:0px;
 }
 .book-rocket{
   margin-top: 25px;
   width: 150px;
 }
 .logo {
   width: 260px;
   margin: 25px 0 0 7px;
 }
 .tagline {
   margin: 20px 0 0 7px;
   font-size:12px;
 }
 .movie-list {
  margin: 40px 0 40px 0px;
 }
 .pages {
  text-align: center;
  margin-top: 10px;
  width:100%;
 }
.pages-top {
  display: block;
  width: 100%;
}
 .movie-list-container{
  width:90%;
  padding-left: 60px;
 }
}
 @media screen and (max-width:480px) {
  .App-header {
    height: 140px;
    width:100%;
  }
  .logo-section {
    margin-left:0px;
  }
  .book-rocket{
    margin-top: 15px;
    width: 102px;
  }
  .logo {
    width: 200px;
    margin: 15px 0 0 5px;
  }
  .tagline {
    margin: 0 0 0 5px;
    font-size:10px;
  }
  .movie-list-container {
    width: 96%;
    padding-left:0px;
  }
  .author-image {
    margin-top:10px;
  }
  .content-area {
    padding-left:0px;
  }
  .pages {
    text-align: center;
    margin-top: 10px;
    width:100%;
   }
  .pages-top {
    display: block;
    width: 100%;
  }
   .thebook {
     width:auto;
   }
  .book-image-p2 {
    float: none;
    height: 120%;
    }
  .each-book {
     width: 420px;
     margin-bottom:40px;
   }
   .each-book, .movie-list {
      margin-left:30px;
   }
   .book-description {
     font-size: 1.3em;
     line-height: 1.8em;
   }
   .author-section {
     padding-top:5px;
     float: none;
     margin: auto;
   }
   .booklist-title{
      clear:both;
      width: 100%;
      text-align:center;
    }
   .footer-text {
     font-size:1em;
   }
 }